import { http } from './config'
import authHeader from './auth-header';

export default {

    soma_total_venda_nova: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }
         // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
         return http.get('/acompanhamento-dash/soma-total-vendas-nova', {
             params: params,
             headers: authHeader() 
       });
       },

       soma_total_venda_migracao: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }
         // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
         return http.get('/acompanhamento-dash/soma-total-vendas-migracao', {
             params: params,
             headers: authHeader() 
       });
       },


       soma_mensalidade_periodo_anterior: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }
         // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
         return http.get('/acompanhamento-dash/mensalidade-periodo-anterior', {
             params: params,
             headers: authHeader() 
       });
       },

       soma_mensalidade_periodo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }
         // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
         return http.get('/acompanhamento-dash/mensalidade-periodo', {
             params: params,
             headers: authHeader() 
       });
       },

       soma_mensalidade_migracao_ano: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }
         // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
         return http.get('/acompanhamento-dash/mensalidade-migracao-ano', {
             params: params,
             headers: authHeader() 
       });
       },


       download_venda_nova_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }

       
          return http.get('/planilha-dash/download-vendas-nova-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });
        },

        download_venda_migracao_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

            const params = {
                 'data-inicial': dataI,
                 'data-final': dataF,
                 'empresa': nomeEmpresa,
                 'tipo': tipo,
                 'filtro': filtro,
                 'opcao': opcao,
                 'produto': produto
             }
    
           
              return http.get('/planilha-dash/download-vendas-migracao-xls', {
                params: params,
                responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
    
              });
            },



       mensalidade_migracao_periodo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params = {
             'data-inicial': dataI,
             'data-final': dataF,
             'empresa': nomeEmpresa,
             'tipo': tipo,
             'filtro': filtro,
             'opcao': opcao,
             'produto': produto
         }
         // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
         return http.get('/acompanhamento-dash/mensalidade-migracao-periodo', {
             params: params,
             headers: authHeader() 
       });
       },


       
}
